@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
*
/* {
   font-family: 'Bellota Text', cursive;
} */
.cwccontainerhead{
text-align: center;
margin-top: 4rem;
}
.cwccontainerhead h1{
margin-bottom: 2rem;
}

.cwccontainer
{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   padding: 5rem;
   padding-top: 10rem;
}
.cwccontainer .card
{
   width: 330px;
   height: 330px;
   padding: 20px 20px;
   margin: 20px;
   background: #f2f3f7;
   box-shadow: 0.6em 0.6em 1.2em #d2dce9,
               -0.5em -0.5em 1em #ffffff;
}
.cwccontainer .card .content
{
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}
.cwccontainer .card .content .imgBx
{
   width: 180px;
   height: 180px;
   border-radius: 50%;
   position: relative;
   margin-bottom: 20px;
   overflow: hidden;
}
.cwccontainer .card .content .imgBx img
{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: cover;
}
.cwccontainer .card .content .contentBx h4
{
   color: #36187d;
   font-size: 1.4rem;
   font-weight: bold;
   text-align: center;
}
.cwccontainer .card .content .contentBx h5
{
   color: #6c758f;
   font-size: 1.2rem;
   font-weight: bold;
   text-align: center;
}
.cwccontainer .card .content .contentBx h6
{
   color: #000000;
   font-size: 1.1rem;
   font-weight: bold;
   text-align: center;
}
.cwccontainer .card .content .sci
{
   margin-top: 20px;
}
.cwccontainer .card .content .sci a
{
   text-decoration: none;
   color: #6c758f;
   font-size: 30px;
   margin: 10px;
   transition: color 0.4s;
}
.cwccontainer .card .content .sci a:hover
{
   color: #0196e3;
}

@media(max-width: 425px){
   .cwccontainer{
      padding: 1rem;
      padding-top: 7rem;
   }
}