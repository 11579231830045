.abouthead{
    text-align: center;
    font-size: 2.5rem;
    text-decoration: underline;
}

.aboutin{
    margin: 5rem;
    display: flex;
    justify-content: space-between;

}
.aboutin img{
    transition: .3s ease;
height: 30rem;
margin-right: 2rem;

}
.aboutincontent{
    box-shadow: 0 8px 12px -6px black;
    padding: 1rem;
}
.aboutin p{
    text-align: justify;
}




.aboutaim{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5rem;
}


.aboutaim h1{
    text-align: center;
    margin-bottom: 3rem;
    text-decoration: underline;
}
.aboutaimtop{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.aboutaimtop h1{
text-align: center;
}
.aimobj li{
text-align: start;
font-size: 18px;
margin-bottom: .5rem;
background-color: rgb(248, 248, 248);
padding: .5rem;
}
.aimobj div{
    width: 50%;
    box-shadow: 0 8px 12px -6px black;
    padding: 1rem;
    text-align: justify;
}
.aboutaimbottom{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.aboutaimbottom p{
    padding: 5rem;
    padding-bottom: 2rem;
    text-align: center;
    font-size: 1.5rem;
}
.aboutaimbottom ul{
    display: flex;

    
}
.aboutaimbottom ul li{
    padding-right: 2rem;
    

}


.chapters{
    margin: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.chapters h1{
    text-align: center;
    margin-bottom: 5rem;
    margin-bottom: 2rem;
    text-decoration: underline;
}
.chapters ul{
    display: flex;
}
.chapters ul li{
    margin-right: 2rem;
}
.chapters p{
    margin-top: 1rem;
    text-align: center;
    font-size: 1.2rem;
}
@media(max-width: 1450px){
    .aboutin img{
        height: 25rem;
        width: 35rem;
        margin-right: 0rem;
        
        }
        .aboutin{
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
 
        .aimobj{
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            column-gap: 0rem;
        }
        .aboutaimtop{
            display: flex;
            justify-content: center;
            align-items: center;
        }

}
@media(max-width: 1260px){
  
        .aimobj div{
            width: 70%;
            box-shadow: 0 8px 12px -6px black;
            padding: 1rem;
            text-align: justify;
        }

}
@media(max-width: 950px){
  
    .aboutaimbottom p{
        margin-top: 2rem;
        padding: 1rem;
        font-size: 1.2rem;
    }
}
@media(max-width: 720px){
    .aboutin{
        margin: 1rem;
        margin-top: 5rem;
    }
    .aboutin img{
        height: 15rem;
        width: 25rem;
        margin-right: 0rem;
        
    }
    .aboutincontent{
        margin-left: 0rem;
    }
    .aboutaimbottom ul{
        flex-wrap: wrap;
    }
    .aboutaimbottom p{
        padding: 0rem;
        text-align: justify;
    }
    .aboutaim{
        margin: 1rem;
    }
    .chapters{
        margin: 1rem;
    }
}
@media(max-width: 550px){
    .aimobj{
        grid-template-columns: 1fr;
      transform: translateX(8%);
    }
}

@media(max-width: 410px){
    .aboutin img{
        height: 10rem;
        width: 15rem;
    }
}