#partners{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
}
#partnerslogo{
    display: flex;
    flex-direction: column;
}
#partnerslogo h2{
text-decoration: underline;
color: #0077BD;
}
#goldpartner div{
    display: flex;
    justify-content: center;
width: 100%;
}
#silverpartner div{
    display: flex;
    justify-content: center;
    width: 100%;
}
#bronzepartner div{
    display: flex;
    justify-content: center;
width: 100%;
}
#allevents {
    display: flex;
    justify-content: center;
width: 100%;
margin-top: -2rem;
}

#goldpartner{
    display: flex;
    justify-content: center;
    align-items: center;
}

#silverpartner{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

#bronzepartner{
  display: flex;
    justify-content: center;
    align-items: center;
}
#bothpartner{
  display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 790px) {

#goldpartner{
    display: flex;
    flex-direction: column;
      }
      #silverpartner{
    display: flex;
    flex-direction: column;
      }
      #bothpartner{
    display: flex;
    flex-direction: column;
      }
      #bronzepartner{
    display: flex;
    flex-direction: column;
      }
      #allevents{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
      }

    }