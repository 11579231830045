#stickynav{
  position: fixed;
  height: 6rem;
}

.dropdown:hover>.dropdown-menu {
    display: block;
  }
  
  .dropdown>.dropdown-toggle:active {
    pointer-events: none;
  }
  
  .navbar .container-fluid {
    background-color: transparent;
  }
  
  #navbarSupportedContent {
margin-left: auto;
  }
  
  #navbarSupportedContent ul{
    /* border-bottom: 1px solid black; */
  }
  
  #navbarSupportedContent .nav-item {
    padding: .8rem;
    color: rgb(0, 0, 0);
    font-size: 1.2rem;
  }
  
  #navbarSupportedContent .nav-item .nav-link {

    color: rgb(0, 0, 0);
  }
  
  .nav-link {
    font-size: 1.2rem;
    font-weight: 700;
  }
  
  .dropdown-item a {
    color: black;
  }
  
  .nav-link:hover {
    text-decoration: underline;
    transform: scale(1.1);
    transition: .3s ease;
  }
  
  #loginbtn {
    background-color: black;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
    color: white;
    text-decoration: none;
    margin-right: 1rem;
    transition: .3s ease;
    border: 1px solid black;
  }
  
  #loginbtn:hover {
    background-color: white;
    border: 1px solid black;
    color: black;
    text-decoration: none;
    margin-right: 1rem;
  }

  
  /* Responsive adjustments */
  @media (max-width: 1112px) {
    #navbarSupportedContent .nav-item {
      padding: .3rem;
      color: rgb(0, 0, 0);
      font-size: 1.1rem;
    }
  }

  @media (max-width: 992px) {
    .nav-link:hover {
      text-decoration: underline;
      transform: scale(1);
      transition: .3s ease;
    }
    
    #navbarSupportedContent {
      margin-left: 0;
      border-bottom: none;
      background-color: white;
    }
  
  
    .nav-link {
      font-size: 0.9rem;
    }
  
    #loginbtn {
      margin-right: 0;
    }
  }
  
  @media (max-width: 768px) {
    #nav-logo{
      height: 3rem;
    }
    .navbar-nav {
      flex-direction: column;
    }
  
    .navbar-nav .nav-item {
      width: 100%;
      text-align: center;
    }
  
    .navbar-nav .dropdown-menu {
      position: static;
      display: none;
    }
  
    .navbar-nav .dropdown:hover>.dropdown-menu {
      display: block;
    }
  }
  