#calanderevent{
    padding-top: 1rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#calanderevent button{
 background-color: transparent;
 border: 1px solid black;
 transition: .5s ease;
 width: 20%;

}
#calanderevent button a{
font-size: 1.3rem;
text-decoration: none;
color: black;

}
#calanderevent button:hover a{
color: white;
}
#calanderevent button:hover{
background-color: black;
}
#calanderevent img{
margin-bottom: 2rem;
width : 60%;
}
.eventheading{
   padding: 1rem;
   padding-top: 8rem;
}
.eventheading h1{
    color: #0077BD;
    text-decoration: underline;
}
@media (max-width: 768px) {
    #calanderevent button{
        width: 50%;
       
       }
       #calanderevent img{
        margin-bottom: 2rem;
        width : 100%;
        }
   
}
@media (max-width: 500px) {
    #calanderevent button{
        width: 100%;
       
       }
    #calanderevent button a{
  font-size: 1.2rem;
       
       }
       #calanderevent img{
        margin-bottom: 2rem;
        width : 100%;
        }
   
}