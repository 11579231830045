.bloghead h1{
    padding-top: 8rem;
    font-size: 3.5rem;
    margin-bottom: 2rem;    
}
.blogcontainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px; /* Add some gap between the grid items */
    padding: 20px; /* Optional: Add some padding around the container */
}

.blog-post {
    background-color: #dbf4ff21;
    box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column; /* Ensure content stacks vertically */
    align-items: center;
    border-radius: 0; /* Sharper corners */
    padding: 2rem;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
    width: 100%; /* Ensure the blog post takes the full width of the container */
}

.blog-post_img {
    width: 100%;
    height: 200px; /* Set a fixed height for the images */
    margin-bottom: 1rem; /* Add some space below the image */
    overflow: hidden; /* Hide any overflow */
    border-radius: 0; /* Sharper corners */
}

.blog-post_img img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the entire area */
    border-radius: 0; /* Sharper corners */
    display: block;
}

.blog-post_date span {
    display: block;
    color: #00000080;
    font-size: 1.2rem;
    font-weight: 600;
    margin: .5rem 0;
}

.blog-post_title {
    font-size: 1.6rem;
    margin: 1rem 0 1.5rem;
    text-transform: uppercase;
    color: #4facfe;
    text-align: left;
    width: 100%; /* Ensure the title takes the full width */
}

.blog-post_text {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    color: #000000b3;
    width: 100%; /* Ensure the text takes the full width */
}

.blog-post_cta {
    display: inline-block;
    padding: .8rem 1.5rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: .9rem;
    color: #fff;
    text-decoration: none;
    border-radius: 0; /* Sharper corners */
    background: linear-gradient(to right, #c945cf 0%, #04a6bd 100%);
}

.blog-post_cta:hover {
    background: linear-gradient(to right, #04a6bd 0%, #c945cf 100%);
}


@media screen and (max-width: 1068px) {
    .blogcontainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .blog-post {
        max-width: 30rem;
    }
    .blog-post_img {
        width: 100%;
    }
}

@media screen and (max-width: 868px) {
    .blog-post {
        max-width: 25rem;
    }
}

@media screen and (max-width: 768px) {
    .blogcontainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .blog-post {
        padding: 1.5rem;
        width: 100%;
    }
    .blog-post_img {
        width: 100%;
        transform: translate(0, -1rem);
    }
}

@media screen and (max-width: 823px) {
    /* Add any additional styles for smaller screens if needed */
}
    