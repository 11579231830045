/* Google Font CDN Link */
.contacthead h1{
padding-top: 8rem;
    text-align: center;
    text-decoration: underline;
}

#contact{
    margin-top: 5rem;
    margin-bottom: 5rem;
}
#contact{
  width: 85%;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
#contact .content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#contact .content .left-side{
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before{
  content: '';
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details{
  margin: 14px;
  text-align: center;
}
.content .left-side .details i{
  font-size: 30px;
  color: #67AF76;
  margin-bottom: 10px;
}
.content .left-side .details .topic{
  font-size: 20px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two{
  font-size: 16px;
  color: #afafb6;
}
#contact .content .right-side{
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text{
  font-size: 23px;
  font-weight: 600;
  color: #0077BD;
}
.right-side .input-box{
  height: 50px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea{
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #F0F1F8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box{
  min-height: 110px;
}
.right-side .input-box textarea{
  padding-top: 6px;
}
.right-side .button{
  display: inline-block;
  margin-top: 12px;
}
.right-side .button button{
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #0077BD;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button button:hover{
  background: #0077BD;
}

@media (max-width: 950px) {
  #contact{
    width: 90%;
    padding: 30px 40px 40px 35px ;
  }
  #contact .content .right-side{
   width: 75%;
   margin-left: 55px;
}
}
@media (max-width: 820px) {

  .contactContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #contact{
    margin: 40px 0;
    height: 100%;
  }
  #contact .content{
    flex-direction: column-reverse;
  }
 #contact .content .left-side{
   width: 100%;
   flex-direction: row;
   margin-top: 40px;
   justify-content: center;
   flex-wrap: wrap;
 }
 #contact .content .left-side::before{
   display: none;
 }
 #contact .content .right-side{
   width: 100%;
   margin-left: 0;
 }
}

