.aboutpresident{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem;
    padding-top: 10rem;
}
.aboutpresidenthead{
    font-size: 2.5rem;
    text-decoration: underline;
    font-weight: 400;
}
.aboutpresidentcontent{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    margin-top: 5rem;
}
.aboutpresidentcontent img{
height: 27rem;
}
.aboutpresidentcontent p{
    font-size: 1.5rem;
    text-align: justify;
    margin-left: 2rem;
    width: 42rem;
    padding: 1rem;
    box-shadow: 0 8px 12px -6px black;
}
.presidentlist{
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.presidents{
    display: flex;
    margin-top: 3rem;
}
.presidentlist ul{
    margin-left: 5rem;
}
.presidentlist h2{
    text-decoration: underline;
}
.presidentlist ul li{
    text-align: justify;
}
.presidentlist p{
    margin: 8rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    
}
@media (max-width: 1370px){
    .aboutpresidentcontent{
        flex-direction: column;
    }
    .aboutpresidentcontent p {
        margin-top: 1rem;
        margin-left: 0rem;
    }
    .presidentlist h1{
        text-align: center;
    }
    .presidents{
        flex-wrap: wrap;
    }
    .presidentlist ul {
        margin-left: 1rem;
    }
    .presidentlist p {
    margin: 1rem;
    }
    
}
@media (max-width: 1370px){
    .aboutpresident{
        padding: 0rem;
        padding-top: 10rem;
    }
}
@media (max-width: 784px){
    .aboutpresidentcontent  {
     justify-content: center;
     align-items: center;
    }

    .aboutpresidentcontent img {
        height: 20rem;
        width:  30rem;
    }
    .aboutpresidentcontent p{
     font-size: 1rem;
     width: auto;
    }
}
@media (max-width: 500px){
    .aboutpresidentcontent  {
     justify-content: center;
     align-items: center;
    }

    .aboutpresidentcontent img {
        height: 10rem;
        width:  15rem;
    }
   
}
