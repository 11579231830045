
footer {
  margin-top: 2rem;
}

.footer-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-section {
  text-align: center;
  margin-bottom: 2rem;
}

.footer-text {
  margin: 1rem 0;
  color: #343a40;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.footer-column {
  margin: 1rem;
  min-width: 150px;
}

.footer-column h5 {
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.footer-column a {
  display: block;
  margin-bottom: 0.5rem;
  color: #343a40;
  text-decoration: none;
  text-align: center;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-social {
  display: flex;
  justify-content: center;
  margin: 1.5rem 0;
}

.footer-social a {
  color: white;
  background-color: #343a40;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 0.5rem;
  text-decoration: none;
}

.footer-social a:hover {
  background-color: #007bff;
}
.footer-copyright {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #343a40;
}

/* Responsive Adjustments */
@media (max-width: 1045px) {
  .footer-column {
 display: none;
  }
  .footer-column:nth-child(5) {
 display: block;
  }
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    min-width: 100%;
    text-align: center;
  }
}
