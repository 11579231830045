/* BlogDetail.css */
.blog-detail-head h1{
text-align: center;
font-size: 3rem;
padding-top: 3rem;
}
.blog-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 10rem;
  }
  
  .blog-detail-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-detail-header h1 {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  .blog-detail-date {
    font-size: 1.2em;
    color: #888888;
    font-weight: 600;
  }
  
  .blog-detail-image {
    text-align: center;
    margin-bottom: 20px;
    
  }
  
  .blog-detail-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .blog-detail-content {
    font-size: 1.2em;
    line-height: 1.6;
    color: #555;
  }
  
  .blog-detail-content p {
    margin-bottom: 20px;
    text-align: justify;
    font-size: 1.1rem;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }

  @media(max-width: 425px){
    .blog-detail-header h1 {
      font-size: 1.8em;
    
    }
  }